<template>
  <CContainer>
    <CRow class="justify-content-center">
      <CCol md="6">
        <CCard class="p-4">
          <CCardBody>
            <CForm @submit.prevent="onSubmit">
              <h1 class="text-center">{{ $t("public.header.register") }}</h1>
              <p class="text-center text-muted">
                {{ $t("public.register.info") }}
              </p>
              <CAlert show color="danger" v-for="item in errors" :key="item">{{
                item
              }}</CAlert>
              <CInput
                v-model.trim="$v.form.email.$model"
                @input="$v.form.email.$touch"
                :placeholder="$t('public.register.placeholder_email')"
                autocomplete="email"
                :is-valid="isValid($v.form.email)"
                invalid-feedback="Please enter a valid email"
              >
                <template #prepend-content
                  ><CIcon name="cil-envelope-open"
                /></template>
              </CInput>

              <CInput
                v-model.trim="$v.form.firstname.$model"
                @input="$v.form.firstname.$touch"
                :placeholder="$t('public.register.placeholder_firstname')"
                type="text"
                autocomplete="firstname"
                :is-valid="isValid($v.form.firstname)"
                invalid-feedback="Field required"
              >
                <template #prepend-content><CIcon name="cil-user" /></template>
              </CInput>
              <CInput
                v-model.trim="$v.form.lastname.$model"
                @input="$v.form.lastname.$touch"
                :placeholder="$t('public.register.placeholder_lastname')"
                type="text"
                autocomplete="lastname"
                :is-valid="isValid($v.form.lastname)"
                invalid-feedback="Field required"
              >
                <template #prepend-content><CIcon name="cil-user" /></template>
              </CInput>

              <CInput
                v-model.trim="$v.form.password.$model"
                @input="$v.form.password.$touch"
                :placeholder="$t('public.register.placeholder_password')"
                type="password"
                autocomplete="password"
                :is-valid="isValid($v.form.password)"
                invalid-feedback="Min 8 characters, alphanumeric"
              >
                <template #prepend-content
                  ><CIcon name="cil-lock-locked"
                /></template>
              </CInput>

              <CInput
                v-model.trim="$v.form.password_confirm.$model"
                @input="$v.form.password_confirm.$touch"
                :placeholder="
                  $t('public.register.placeholder_password_confirm')
                "
                type="password"
                autocomplete="password_confirm"
                :is-valid="isValid($v.form.password_confirm)"
                invalid-feedback="Passwords must be identical."
              >
                <template #prepend-content
                  ><CIcon name="cil-lock-locked"
                /></template>
              </CInput>

              <vue-ladda
                :loading="loading"
                data-style="zoom-in"
                button-class="btn btn-block btn-primary"
                type="submit"
              >
                {{ $t("public.register.button_sign_up") }}
              </vue-ladda>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import { apiUrl } from "../../http";

export default {
  name: "Registration",
  data() {
    return {
      loading: false,
      errors: [],
      form: {
        captcha: "",
        email: "",
        firstname: "",
        lastname: "",
        password: "",
        password_confirm: "",
      },
    };
  },

  validations: {
    form: {
      email: { required, email },
      firstname: { required },
      lastname: { required },
      password: { required, minLength: minLength(8) },
      password_confirm: { required, sameAsPassword: sameAs("password") },
    },
  },

  async mounted() {
    await this.$recaptchaLoaded();
    if (this.$recaptchaInstance) {
      this.$recaptchaInstance.showBadge();
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.$recaptchaInstance) {
      this.$recaptchaInstance.hideBadge();
    }
    next();
  },

  methods: {
    async onSubmit() {
      this.$v.$touch();
      const isInvalid = this.$v.$invalid;
      if (!isInvalid) {
        this.loading = true;
        try {
          this.form.captcha = await this.$recaptcha("registration");

          const resp = await this.axios.post(apiUrl(`/api/account/signup/`), {
            captcha: this.form.captcha,
            email: this.form.email,
            first_name: this.form.firstname,
            last_name: this.form.lastname,
            password1: this.form.password,
            password2: this.form.password_confirm,
          });

          if (resp && resp.data && resp.data.token) {
            this.loginUserByTokenAndRedirect(resp.data.token);
          }
        } catch (error) {
          this.loading = false;
          let errors = [];
          Object.keys(error.response.data).forEach((key) => {
            errors = errors.concat(error.response.data[key]);
          });
          this.errors = errors;
        }
      }
    },
    isValid($field) {
      const status =
        $field.$dirty && $field.$invalid === true ? false : undefined;
      return status;
    },

    loginUserByTokenAndRedirect(token) {
      const self = this;
      this.$auth.token(null, token, false);
      this.$auth
        .refresh()
        .then(() => {
          return this.$auth.fetch();
        })
        .then(() => {
          self.$router.push({ path: "/" });
        })
        .catch((error) => {
          self.loading = false;
          self.errors(error.response);
        });
    },
  },
};
</script>
